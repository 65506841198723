import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../helpers/renderStyledElementStyles';

export const Title = styled.div`
  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.module.dStyles.elements.heading)}
`;

export const Hr = styled.hr`
  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.module.vStyles.elements.hr)}
`;

export const Thumbnail = styled.div`
  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.module.dStyles.elements.thumbnail)}
`;

export const DesktopHeadingContainer = styled.div`
  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.dStyles.elements.desktopHeading,
    )}
`;
