import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';

// Shared components
import {
  StyledHeading,
  Heading,
  ImageTag,
  IconBadge,
} from '../../sharedComponents';
import DownloadModuleFooter from '../innerComponents/DownloadModuleFooter';

// Constants
import { DS } from '../constants';

// Styles
import { Hr, Thumbnail, DesktopHeadingContainer } from '../styles';

const PortraitDisplayStyle = (props) => {
  const {
    section: {
      image: { path },
      title,
      titleSize,
      alt,
    },
  } = props;

  const themeConfig = useContext(ThemeContext);
  const {
    contentGrid,
    thumbnailGrid: { xs, sm, md },
  } = themeConfig.module.dStyles;

  return (
    <div className="container">
      <div className="row">
        <div
          className={`col-xs-${xs} col-sm-${sm} col-md-${md}`}
          data-testid={`file-download-${DS.PORTRAIT}`}
        >
          <Thumbnail>
            <ImageTag
              displayStyles={themeConfig.module.dStyles}
              src={path}
              imgixParams="w=550"
              alt={alt}
            />
            <IconBadge
              iconID="get_app"
              displayStyles={themeConfig.module.dStyles}
            />
            <StyledHeading
              content={title}
              as={titleSize}
              displayStyles={themeConfig.module.dStyles}
            />
          </Thumbnail>
        </div>
        <div
          className={`col-xs-${contentGrid.xs} col-sm-${contentGrid.sm} col-md-${contentGrid.md}`}
        >
          <DesktopHeadingContainer>
            <Heading content={title} as={titleSize} />
          </DesktopHeadingContainer>
          <Hr />
          <DownloadModuleFooter {...props.section} />
        </div>
      </div>
    </div>
  );
};

PortraitDisplayStyle.propTypes = {
  section: PropTypes.shape({
    image: PropTypes.shape({
      path: PropTypes.string,
    }),
    title: PropTypes.string,
    titleSize: PropTypes.string,
  }).isRequired,
};

PortraitDisplayStyle.defaultProps = {
  section: {
    image: {},
  },
};

export default PortraitDisplayStyle;
