import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled, { ThemeContext } from 'styled-components';
import { StyledParagraph, CallToAction } from '../../sharedComponents';

export const Container = styled.div`
  > a {
    padding-left: 35px;
    padding-right: 35px;
  }
`;

const DownloadModuleFooter = ({ ctatext, file: { filename }, text }) => {
  const { t } = useTranslation();
  const themeConfig = useContext(ThemeContext);

  return (
    <Container data-testid="file-download-CTA">
      <CallToAction to={`/uploads/${filename}`} size="medium" mode="black">
        {ctatext || t('leadGeneration.downloadNow')}
      </CallToAction>
      <StyledParagraph
        content={text}
        visualStyles={themeConfig.module.vStyles}
      />
    </Container>
  );
};

DownloadModuleFooter.propTypes = {
  ctatext: PropTypes.string,
  file: PropTypes.shape({
    filename: PropTypes.string,
  }),
  text: PropTypes.string,
};

DownloadModuleFooter.defaultProps = {
  file: {},
};

export default DownloadModuleFooter;
