import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';
import { renderStyledElementStyles } from '../../helpers';

// Constants
import { DS, VS } from './constants';

import { SiteBuilderModule } from '../sharedComponents';

// Display styles
import NoneDisplayStyle from './displayStyles/NoneDisplayStyle';
import PortraitDisplayStyle from './displayStyles/PortraitDisplayStyle';
import LandscapeDisplayStyle from './displayStyles/LandscapeDisplayStyle';

const Wrapper = styled.div`
  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.siteBuilderModules.fileDownloadModule,
    )}
`;

const FileDownloadModule = (props) => {
  const {
    section: { thumbStyle: displayStyle = DS.NONE },
  } = props;

  const themeConfig = useContext(ThemeContext);
  const dStyles =
    themeConfig.siteBuilderModules.fileDownloadModule.displayStyles[
      displayStyle
    ];
  const vStyles =
    themeConfig.siteBuilderModules.fileDownloadModule.visualStyles[VS.DEFAULT];

  const renderDisplayStyles = () => {
    if (displayStyle === DS.LANDSCAPE)
      return <LandscapeDisplayStyle section={props.section} />;
    if (displayStyle === DS.PORTRAIT)
      return <PortraitDisplayStyle section={props.section} />;
    return <NoneDisplayStyle section={props.section} />;
  };

  return (
    <SiteBuilderModule
      section={props.section}
      moduleTheme={{ dStyles, vStyles }}
    >
      <Wrapper>{renderDisplayStyles()}</Wrapper>
    </SiteBuilderModule>
  );
};

FileDownloadModule.propTypes = {
  section: PropTypes.shape({
    thumbStyle: PropTypes.oneOf([DS.NONE, DS.PORTRAIT, DS.LANDSCAPE]),
  }).isRequired,
};

export default FileDownloadModule;
