import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';

// Shared components
import { Heading, IconBadge } from '../../sharedComponents';
import DownloadModuleFooter from '../innerComponents/DownloadModuleFooter';

// Constants
import { DS } from '../constants';

// Styles
import { Title, Hr } from '../styles';

const NoneDisplayStyle = (props) => {
  const {
    section: { title, titleSize },
  } = props;

  const themeConfig = useContext(ThemeContext);
  const {
    contentGrid: { xs, sm, md },
  } = themeConfig.module.dStyles;

  return (
    <div className="container">
      <div className="row">
        <div
          className={`col-xs-${xs} col-sm-${sm} col-md-${md}`}
          data-testid={`file-download-${DS.NONE}`}
        >
          <Title>
            <IconBadge isRound iconID="get_app" />
            <Heading content={title} as={titleSize} />
          </Title>
          <Hr />
          <DownloadModuleFooter {...props.section} />
        </div>
      </div>
    </div>
  );
};

NoneDisplayStyle.propTypes = {
  section: PropTypes.shape({
    title: PropTypes.string,
    titleSize: PropTypes.string,
  }).isRequired,
};

export default NoneDisplayStyle;
